kv.controller('legalCaseListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'legal_case_id';
	vm.entity = 'legal_case';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		search: {
			is_active: "1",
			case_status_id: 2,
			case_status_name: 'IN_PROGRESS',
		},
		params: {
			action: 'legal_case'
		},
		url: "legal_case",
		callback: function (response) {
			vm.countLines = 0;
			if (!response.data) return;
			vm.countLines = (response.data ? response.total : 0);
		}
	});

	vm.edit = function (entity) {
		vm.openModal({
			templateUrl: "legal_case-list.ctrl",
			controller: "",
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						index: $index,
						entity: entity
					};
				}
			}
		}).then((result) => {
			if (result.index == -1) {
				return this.infinitScroll.items.push(result.entity);
			} else return Object.assign(entity, result.entity);
		});
	};

	vm.getResponsiblesNames = function(entity) {
		return entity.responsibles.map(function(responsible) { return responsible.full_name; }).join(', ');
	};

	vm.addEditLegalCaseConfigurationSettings = function (){
		vm.openModal({
			templateUrl: "legal-case-configuration-settings",
			controller: 'legalCaseConfigurationSettingsCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: {
					//  entity: entity
				}
			}
		}).then(response => {
			window.location = "/legal_case/";
		});
	};

	vm.moveEntities = function (type_id) {
		const filteredSearch = Object.fromEntries(
			Object.entries(vm.infinitScroll.search).filter(([key, value]) => value !== null)
		);
		vm.openModal({
			templateUrl: 'legal-case-list-move',
			controller: LegalCaseListMoveCtrl,
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: filteredSearch,
						countLines: vm.countLines,
						type: type_id
					};
				},
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
		});
	};

	return vm;
});

