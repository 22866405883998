function LegalCaseListMoveCtrl($uibModalInstance, data, resource, cmdate, $injector, $scope) {
    var vm = new modalCtrl($uibModalInstance, $injector, 'legal_case', 'legal_case_id', $scope, data);
    vm.count = data.countLines;
    vm.data = {};

    vm.saveMove = function () {
        let errors = {};
        if (vm.data.legal_case_id) errors.legal_case_id = "required";
        if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
        vm.confirm(vm.trans("LANG.CONFIRM_MOVE"), vm.trans("LANG.CONFIRM_THE_CHANGES")).then(response => {
            if (response !== "ok") return;
            let postData = angular.copy(data.searchList);
            postData.changeTo = angular.copy(vm.data);
            KApp.block('.modal-content');
            vm.rest.post({
                url: vm.entity + '/actions/change_entities_legal_case',
                params: {
                    action: 'change_entities_legal_case'
                },
                data: postData,
                success: function (response) {
                    if(response.success){
                        toastr.success(response.message);
                    }else{
                        toastr.error(vm.trans('ERROR_WHILE_SAVING'));
                    }
                    KApp.unblock('.modal-content');
                    vm.close('ok');
                }
            });
        });
    };

    return vm;
}
