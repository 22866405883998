kv.controller('legalCaseCnscEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'legal_case_cnsc', 'id', $scope, params);

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];

    if (!page_entity_id && params.entity) {
        page_entity_id = params.entity.id;
    }

    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    vm.isNewRecord = true;
    vm.data = {
    };
    vm.dataHasLoaded = true;
    vm.searchResults = {
        found: false
    };

    if(params.from_view) {
        vm.data.responsibles = vm.params.cnsc_responsibles;
    }


    /**
     * search for a legal case cnsc by number
     * @returns {*}
     */
    vm.findByNumber = function findByNumber() {
        vm.searchResults = {
            found: null,
            message: null
        };

        KApp.block('.modal-content');
        vm.rest.post({
            url: 'legal_case_cnsc/actions/find_by_number',
            data: {
                legal_case : vm.data.dosar,
                year : vm.data.an
            }
        }).then(function (response) {
            if (response.found && response.data) {
                vm.data = response.data;
                vm.searchResults.found = true;
                vm.searchResults.message = 'Case found: ' + response.data.dosar;
            } else {
                vm.searchResults.found = false;
                vm.searchResults.message = 'Case not found';
            }
            KApp.unblock('.modal-content');
        });
    };

    vm.changeResponsible = function changeResponsible() {
        KApp.block('.modal-content');
        vm.rest.post({
            url: 'legal_case_cnsc/actions/change_responsible',
            data: {
                case_id : vm.params.case_id,
                responsibles : vm.data.responsibles
            }
        }).then(function (response) {
            window.location.reload();
            KApp.unblock('.modal-content');
        });
    };

    return vm;
});
