kv.controller('trademarkEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'trademark_id';
    vm.entity = 'trademark';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');
    vm.can_add_new_trademarks = parseInt(vm.bladeParams.canAddNewTrademarks);

    vm.searchResults = {
        found: null,
        message: null
    };

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    if(page_identifier == 'edit') {
        $http.get("/trademark/" + page_entity_id).then(function(response) {
            vm.data = response.data;
            //
            // console.log(vm.data);
            // if (vm.data.trademark_class) {
            //     angular.forEach(vm.data.trademark_class, function (v, i) {
            //         vm.data.trademark_class[i].id = v.sys_trademark_class.sys_trademark_class_id;
            //         vm.data.trademark_class[i].name = v.sys_trademark_class.code;
            //     });
            // }
        });
    } else {
        vm.data = {
            own_portfolio: 1,
            sync_trademark: 1,
        };
        vm.isNewRecord = true;

        // todo: default objects & reset to default before search is done (so data is not mixed)
    }



    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {
                own_portfolio: 1,
                sync_trademark: 1,
            };
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }

        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    /**
     * search for a trademark by ID
     * @returns {*}
     */
    vm.findByID = function findByID() {
        vm.searchResults = {
            found: null,
            message: null
        };

        vm.rest.post({
            url: 'trademark/actions/find_by_id',
            data: {
                institution_id: 1,
                identifier : vm.identifier
            }
        }).then(function (response) {
            if (response.found && response.data) {
                // vm.mapRemoteData(response.data);
                vm.data = response.data;
                vm.searchResults.found = true;
                vm.searchResults.message = 'Trademark found: ' + response.data.name;
            } else {
                vm.searchResults.found = false;
                vm.searchResults.message = 'Trademark not found';
            }
        });
    };

    /**
     * todo: not used
     * map data from search to local vm data
     * @param input
     */
    vm.mapRemoteData = function mapRemoteData(input) {
        // merge current vm.data with json input
        var mergeData = {
            institution_id: input.institution_id,
            institution: input.institution,
            name: input.name,
            alternative_customer_text: input.customer_fiscal_entity_id ? null : input.alternative_customer_text,
            customer_fiscal_entity_id: input.customer_fiscal_entity_id,
            customer_fiscal_entity: input.customer_fiscal_entity,
            type: input.type,
            trademark_type: input.trademark_type,
            status_id: input.status_id,
            status: input.status,
            remote_status_id: input.remote_status_id,
            sys_osim_status: input.sys_osim_status,
            application_id: input.application_id,
            application_number: input.application_number,
            deposit_number: input.deposit_number,
            deposit_date: input.deposit_date,
            registration_number: input.registration_number,
            registration_date: input.registration_date,
            expiration_date: input.expiration_date,
            representative: input.representative,
            holder_details: input.holder_details,
            representation_url: input.representation_url,
            representation_extension: input.representation_extension,
            trademark_class: input.trademark_class,
            bopi: input.bopi,
        };
        vm.data = angular.extend(vm.data, mergeData);
        console.log(vm.data);
    };

    // vm.validateSomeInputs = function () {
    //     vm.custom_errors = null;
    //     vm.has_error = false;
    //
    //     if (!vm.custom_errors) vm.custom_errors = [];
    //     if (vm.can_add_new_trademarks != 1 && vm.data.institution_id == 1) {
    //         $('[ng-model="vm.data.institution_id"] .select2-selection--single').css("border", "1px solid red");
    //         vm.custom_errors.push(vm.trans('LANG.MAX_NUMBER_OF_TRADEMARKS_REACHED'));
    //         vm.has_error = true;
    //     } else {
    //         $('[ng-model="vm.data.institution_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
    //     }
    //
    //     if(vm.has_error){
    //         $('.custom_error_messages').css('display', 'block');
    //     } else {
    //         $('.custom_error_messages').css('display', 'none');
    //         vm.save();
    //     }
    //
    // };
    return vm;
});
