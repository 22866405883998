kv.controller('trademarkMonitorListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'trademark_monitor';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'trademark_monitor'
        },
        url: "trademark_monitor",
    });

    vm.delete = function ($index) {
        vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
            if (response !== 'ok') return;
            $http.delete("trademark_monitor/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
                vm.infinitScroll.items.splice($index, 1);
            });
        });
    };

    vm.addEditEntity = function (entity, $index) {
        vm.openModal({
            templateUrl: "trademark_monitor-edit",
            controller: 'trademarkMonitorEditCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params: {
                    index: $index,
                    entity: entity
                }
            }
        }).then(function (response) {
            if (!response) return;
            if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
            else angular.extend(entity, response.entity);
        });
    };

    return vm;
});

