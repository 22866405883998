kv.controller('trademarkViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
        var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
        vm.primaryKey = 'trademark_id';
        vm.entity = 'trademark';
        vm.entityType = 'trademark';
        vm.groups = {};
        let copyData = {};
        vm.trademark = {};

        let url_segments = window.location.pathname.split('/');
        let page_entity_id = url_segments[2];
        let page_identifier = url_segments[3];

        vm.entityId = page_entity_id;
        vm.commentsConfig = {
            entity_type: vm.entity
        };


        console.log("vm", vm);
        vm.goBack = function () {
            $state.go("app.trademarks");
        };


        vm.edit = function () {
            $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
        };

        vm.buildList = function (data) {
                vm.trademarkTaskList = data;
                copyData = data;
                if (data) {
                        $scope.count = {
                                ongoing: 0,
                                realized: 0
                        };

                        angular.forEach(vm.trademarkTaskList, function (v, i) {
                                if (v.done !== true) {
                                        $scope.count.ongoing = parseInt($scope.count.ongoing) + 1;
                                } else {
                                        $scope.count.realized = parseInt($scope.count.realized) + 1;
                                }
                        });
                }
        };

        vm.getTaskList = function () {
                if(page_entity_id) {
                        let taskListRest = resource.init('trademark');
                        taskListRest.get({
                                url: 'trademark/actions/taskList?trademark_id=' + vm.entityId,
                                params: {
                                        action: 'taskList',
                                }
                        }).then(function (response) {
                                vm.trademarkTaskList = response.tasks;
                                vm.trademarkTaskListCount = null;
                                $.each(response.tasks, function (index, value) {
                                        if (value.done !== true) {
                                                vm.trademarkTaskListCount++;
                                        }
                                        vm.trademarkTaskList[index].responsible_count = value.users.length;
                                });
                                vm.trademark.tasks_count = vm.trademarkTaskListCount;
                                vm.buildList(vm.trademarkTaskList);
                        });
                }
        };

        /**
         * filter task list by selected status
         * @param  {[type]} status [description]
         * @return {[type]}        [description]
         */
        vm.filterList = function (status) {
                if (status) {
                        vm.trademarkTaskList = [];
                        angular.forEach(copyData, function (v, i) {
                                if (v.done === false && status === 'ongoing') {
                                        vm.trademarkTaskList = vm.trademarkTaskList.concat(v);
                                }
                                if (status === 'realized' && v.done === true) {
                                        vm.trademarkTaskList = vm.trademarkTaskList.concat(v);
                                }
                                if (status === 'all') {
                                        vm.trademarkTaskList = copyData;

                                }
                        });
                }
        };

        /**
         * add task
         * @param {[type]} entity    [description]
         */

        vm.addEditTask = function (entity) {
                let trademark = JSON.parse(vm.bladeParams.trademark);
                vm.openModal({
                        templateUrl: "task-edit",
                        size: 'lg',
                        controller: "taskEditCtrl",
                        controllerAs: "vm",
                        resolve: {
                                params: function () {
                                        return {
                                                entity: entity,
                                                task_id: entity ? entity.task_id : null,
                                                trademark: {
                                                        trademark_id: trademark.trademark_id,
                                                        name: trademark.name,
                                                        deposit_number: trademark.deposit_number
                                                },
                                                trademark_id: trademark.trademark_id
                                        };
                                }
                        }

                }).then(response => {
                        if (!response) return;
                        vm.getTaskList();
                });
        };

        vm.deleteTask = function deleteEntity($index) {
                this.openModal({
                        animation: true,
                        component: 'modalConfirm',
                        resolve: {
                                title: function () {
                                        return 'DELETE';
                                },
                                content: function () {
                                        return $filter('trans')('LANG.ARE_YOU_SURE_DELETE');
                                }
                        }
                }).then(() => {
                        let rest = this.getInjection('resource').init("task");
                        let ctrl = this;
                        rest.delete({
                                params: {
                                        id: vm.trademarkTaskList[$index].task_id
                                },
                                success: function () {
                                        vm.trademarkTaskList.splice($index, 1);
                                        vm.getTaskList();
                                }
                        });
                });
        };

        /**
         * change task status
         * @param  {[type]} entity [description]
         */
        vm.changeStatus = function (entity) {
                var REST_task = resource.init("task");
                entity.done = !entity.done;
                REST_task.update({
                        id: entity.task_id,
                        params: {
                                action: (entity.done ? 'markCompleted' : 'markToDo')
                        }
                }).then(function () {
                        vm.getTaskList();
                });
        }

       // if (vm.trademark.tasks_count > 0) {
                vm.getTaskList();
      //  }
        return vm;
    }
);

