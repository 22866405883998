kv.controller('legalCaseCnscMonitorEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'legal_case_cnsc_monitor', 'id', $scope, params);

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];

    if (!page_entity_id && params.entity) {
        page_entity_id = params.entity.id;
    }

    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    if (page_entity_id) {
        $http.get("/legal_case_cnsc_monitor/" + page_entity_id)
            .then(function (response) {
                vm.isNewRecord = false;
                vm.data = response.data;
                vm.dataHasLoaded = true;
                KApp.unblock('.modal-content');
            });
    } else {
        vm.isNewRecord = true;
        vm.data = {
            is_active: 1,
        };
        vm.dataHasLoaded = true;
    }

    return vm;
});
