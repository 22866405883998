kv.controller('initiatorCustomersStatsCtrl', function ($uibModalInstance, params, $scope, $injector, $timeout) {
    let vm = this;

    // Ensure the params data is correctly passed
    vm.data = params.entity;
    vm.type = params.type;

    // Modal is rendered
    vm.modal = $uibModalInstance;
    // vm.modal.rendered.then(() => {
    //     if (!vm.dataHasLoaded) {
    //         KApp.block('.modal-content');  // Assuming KApp.block is part of your code.
    //     }
    // });

    var chartData  = Object.values(vm.data.chart);

    vm.cancel = function() {
        vm.modal.dismiss('cancel');
    };

    function renderChart() {
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: 450,
                width: 800,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} % ({point.percentage:.1f} % din selectie)</b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false,
                    innerSize: '60%'
                }
            },
            series: [{
                data: chartData
            }]
        };

        // Initialize Highcharts and store the chart instance
        var chart = $('#topCustomersChart').highcharts(config);

        // Show the chart container and trigger reflow after a slight delay
        // $('#topCustomersChart').show();
    }

    $(document).ready(function() {
        // Simulate opening the modal
        $('#myModal').show();

        // Render the chart after the modal is shown
        renderChart();
    });

    return vm;
});
