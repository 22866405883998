kv.controller('TrademarkSyncCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'trademark_id';
    vm.entity = 'trademark';
    vm.filter.value = "";
    vm.rest = vm.getInjection('resource').init('trademark');
    vm.data = {
        items: [],
        totals: {},
    };
    vm.status_filter = '';
    vm.search_institution = "1"; // Set OSIM as default

    /**
     * search trademarks
     */
    vm.checkTrademarksOnOsim = function () {
        KApp.block(".table-sync");
        vm.rest.get({
            url: "trademark/checkTrademarksOnOsim",
            params: {
                action: 'checkTrademarksOnOsim',
            }

        }).then(response => {
            if (response && response.trademarks) {
                // map the data.data and add status_class based on sync_status
                vm.data.items = response.trademarks.map(function(entity) {
                    switch(entity.sync_status) {
                        case 'not_found':
                            entity.status_class = 'badge-danger';
                            entity.status_name = 'not found';
                            break;
                        case 'not_changed':
                            entity.status_class = 'badge-secondary';
                            entity.status_name = 'unchanged';
                            break;
                        case 'updated':
                            entity.status_class = 'badge-warning';
                            entity.status_name = 'updated';
                            break;
                        default:
                            entity.status_class = 'badge-secondary';
                            entity.status_name = 'unknown';
                    }
                    return entity;
                });

                vm.data.totals = response.totals;
                vm.copyData = angular.copy(vm.data.items);
                // vm.applyFilter();
            }
        }).catch(function(error) {
            KApp.unblock(".table-sync");
            console.error(error);
            toastr.error('An error occurred while applying changes to the entities.');
        }).finally(() => {
            KApp.unblock(".table-sync");
        });
    };

    /**
     * apply changes for trademark
     * @param entity
     */
    vm.applyChangesForTrademark = function(entity) {
        // raise error if no entity or no trademark_id or no changes
        if (!entity || !entity.trademark_id || !entity.changes) {
            toastr.error('Invalid entity.');
            return;
        }

        // simplify post data
        let changedEntity = {
            trademark_id: entity.trademark_id,
            changes: entity.changes
        };

        KApp.block(".table-sync");

        vm.rest.post({
            url: 'trademark/actions/apply_changes_for_trademark',
            params: {
                action: 'apply_changes_for_trademark'
            },
            data: changedEntity
        }).then(function (response) {
            if(response.status) {
                toastr.success(response.message);

                // Remove the updated item from the list (find it by matching its id or a unique key)
                vm.data.items = vm.data.items.filter(function(item) {
                    // Assuming `entity.id` is the unique identifier, remove the item from the list if its ID matches
                    return item.trademark_id !== entity.trademark_id;
                });
                vm.copyData = vm.data.items;
            }
        }).catch(function(error) {
            console.error(error);
            toastr.error('An error occurred while applying changes to the entity.');
        }).finally(() => {
            KApp.unblock(".table-sync");
        });
    };

    /**
     * mass apply changes for all trademarks
     */
    vm.applyAllChanges = function()
    {
        const entitiesToImport = vm.data.items
            // only get changes
            .filter(function(item) {
                return item.changes_count > 0;
            })
            // simplify post data
            .map(function(item) {
                return {
                    trademark_id: item.trademark_id,
                    changes: item.changes
                };
            });

        if (entitiesToImport.length === 0) {
            toastr.warning('No entities with changes to import.');
            return;
        }

        KApp.block(".table-sync");
        toastr.info(entitiesToImport.length + ' entities with changes will be updated.');

        vm.rest.post({
            url: 'trademark/actions/apply_all_changes_for_trademarks',
            params: {
                action: 'apply_all_changes_for_trademarks'
            },
            data: {
                data : entitiesToImport
            }
        }).then(function (response) {
            KApp.unblock(".table-sync");
            if (response.status) {
                toastr.success(response.message);

                // Remove the updated items from the list
                vm.data.items = vm.data.items.filter(function(item) {
                    // Keep items that are not in entitiesToImport
                    return !entitiesToImport.some(function(importedItem) {
                        return importedItem.trademark_id === item.trademark_id;
                    });
                });
                vm.copyData = vm.data.items;
            }
            // window.open('/trademark/' + response.empowerment_id, '_blank');
        }).catch(function(error) {
            console.error(error);
            toastr.error('An error occurred while applying changes to the entities.');
        }).finally(() => {
            KApp.unblock(".table-sync");
        });
    };

    vm.filterList = function (status) {
        if (status) {
            // Reset `vm.data.items` to the original list before applying the filter
            vm.data.items = angular.copy(vm.copyData);

            // Filter items based on the status
            if (status === 'updated') {
                vm.data.items = vm.data.items.filter(function (item) {
                    return item.state === 'updated';
                });
            }
            if (status === 'fara_schimbari') {
                vm.data.items = vm.data.items.filter(function (item) {
                    return item.state === 'fara schimbari';
                });
            }
            if (status === 'neidentificata') {
                vm.data.items = vm.data.items.filter(function (item) {
                    return item.state === 'neidentificata';
                });
            }
            if (status === 'all') {
                vm.data.items = angular.copy(vm.copyData); // Reset to all items
            }
        }
    };

   // vm.checkTrademarksOnOsim();
    return vm;
});

