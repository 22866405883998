kv.controller('TimesheetDelayReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

    let graphData = {};
    if ($element.find('#page-data') && $element.find('#page-data').attr('graph-data')) {
        graphData = JSON.parse($element.find('#page-data').attr('graph-data'));
    }
    var params = kvUtils.getAllQueryParams();

    console.log('graphData', graphData);

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    /**
     * show a popup with drill down by customer
     * @param userId
     */
    vm.openUserTimesheetDetails = function (userId, event) {
        if (!graphData[userId]) {
            return;
        }

        var element = event.currentTarget;
        vm.openModal({
            templateUrl: 'popup-chart',
            controller: 'popupChartCtrl',
            controllerAs: 'vm',
            size: 'xl',
            resolve: {
                params: {
                    title: graphData[userId].name,
                    chartConfig : {
                        chart: {
                            type: 'spline', // Spline chart type
                            backgroundColor: 'transparent', // Optional: make the chart background transparent
                        },
                        title: {
                            text: '', // No title
                        },
                        xAxis: {
                            visible: false, // Hide the x-axis
                            tickLength: 0, // No ticks
                            labels: {
                                enabled: false // No labels
                            }
                        },
                        yAxis: {
                            visible: false, // Hide the y-axis
                            tickLength: 0, // No ticks
                            labels: {
                                enabled: false // No labels
                            }
                        },
                        legend: {
                            enabled: false // Disable the legend
                        },
                        tooltip: {
                            enabled: true,  // Make sure tooltip is enabled
                            formatter: function () {
                                return this.y.toFixed(2) + ' days'; // Format the value with ' days'
                            }
                        },
                        plotOptions: {
                            spline: {
                                marker: {
                                    enabled: false // Disable markers on the line
                                }
                            }
                        },
                        series: [{
                            data: graphData[userId].details, // Your data here
                            name: null // Ensure there's no name for the series
                        }]
                    }
                }
            }
        });
    };

	return vm;
});
