kv.controller('memberListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'people_group_id';
	vm.entity = 'people_group';
	vm.count = {
		total: 0,
		showing: 0
	};

	var sys_attendee_status_rest = resource.init("sys_attendee_status");
	sys_attendee_status_rest.getArray({
		"url": "filter/sys_attendee_status/all_sys_attendee_status"
	}).then(function (res) {
		vm.sys_attendee_status = res;
	});

	vm.getInfinitScroll({
		params: {
			action: vm.bladeParams.memberListEntity,
			activity_id: vm.bladeParams.groupId
		},
		url: vm.bladeParams.memberListUrl + "/list"
	});

	//console.log(vm.bladeParams);

	vm.addFromGroup = function addFromGroup() {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'group-list',
			controller: function (bladeParams, baseCtrl, $uibModalInstance, resource, $filter) {
				var vm = this;
				vm.bladeParams = bladeParams;
				vm.baseCtrl = baseCtrl;
				var REST = resource.init("group");
				REST.getArray({
					"url": "filter/group/all_group"
				}).then(function (res) {
					vm.result = res;
				})

				vm.action = {
					add: add,
					cancel: cancel,
				};

				function add($index) {
					REST.create({
						url: vm.bladeParams.memberListEntity + "/" + vm.bladeParams.membersPeoplesTable + '/addFromGroup',
						data: {
							group_id: vm.result[$index].id,
							entity_id: vm.bladeParams.groupId,
							entity_type: vm.bladeParams.memberListEntity,
							role: 'participant'
						}
					}).then(function (res) {
						if(res.status == true) {
							vm.baseCtrl.getInfinitScroll({
								params: {
									action: vm.baseCtrl.bladeParams.memberListEntity
								},
								url: vm.baseCtrl.bladeParams.memberListUrl + "/list"
							});

							$uibModalInstance.close({});
						} else {
							vm.baseCtrl.alert("LANG.ERROR", "LANG." + res.error);
						}
					})
				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				};
			},
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				bladeParams: function () {
					return vm.bladeParams;
				},
				baseCtrl: function () {
					return vm;
				},
			}
		});

		modalInstance.result.then(function (data) {
			vm.infinitScroll.freshResult = new Date().getTime();
		});
	};

	vm.changeStatus = function changeStatus($attendee_index, $status_index) {
		setTimeout(function () {
			var REST = resource.init(vm.bladeParams.membersPeoplesTable);
			if (vm.infinitScroll.items[$attendee_index].status_id == null) {
				vm.infinitScroll.items[$attendee_index].status_id = vm.infinitScroll.items[$attendee_index].status.attendee_status_id;
				vm.infinitScroll.items[$attendee_index].status.status_name = '---';
			} else {
				vm.infinitScroll.items[$attendee_index].status_id = vm.sys_attendee_status[$status_index].id;
				vm.infinitScroll.items[$attendee_index].status.status_name = vm.sys_attendee_status[$status_index].name;
			}

			REST.update({
				url: vm.bladeParams.memberListUrl + "/:relatedId",
				params: {
					relatedId: vm.infinitScroll.items[$attendee_index][vm.bladeParams.membersPeoplesTableId],
				},
				data: vm.infinitScroll.items[$attendee_index]
			}).then(function (data) {
			});
		}, 200);
	};

	vm.deleteMember = function deleteEntity($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then((response) => {
			if (response !== 'ok') return;
			let REST = resource.init(vm.bladeParams.membersPeoplesTable);
			let $processedRelatedID = vm.bladeParams.memberListEntity == 'group' ? "people_id" : vm.bladeParams.membersPeoplesTableId;

			REST.delete({
				url: vm.bladeParams.deleteUrl,
				params: {
					relatedId: vm.infinitScroll.items[$index][$processedRelatedID],
				}
			}).then(function (data) {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	vm.addMember = function (people_type) {
		vm.openModal({
			templateUrl: "people-list",
			controller: 'addMemberListCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				bladeParams: function () {
					return vm.bladeParams;
				},
				data: function () {
					$(document).find("html").css("overflow", "hidden");
					return {
						people_type: people_type,
						entity_id: vm.bladeParams.groupId,
						entity: vm.bladeParams.memberListEntity,
						entity_related: vm.bladeParams.membersPeoplesTable,
					};
				}
			}
		}).then((result) => {
			if (result.length) vm.infinitScroll.search.refresh++;
		});
	};

	vm.addComment = function addComment(attendee, $related_id, $entity_id) {
		vm.openModal({
			templateUrl: 'comment',
			controller: function (bladeParams, $scope, $uibModalInstance, data, resource) {
				var cm = this;
				cm.action = {
					save: save,
					cancel: cancel
				};
				$scope.attendee = data.attendee;

				var REST = resource.init('comment');
				REST.get({
					url: vm.bladeParams.memberListEntity + "/list",
					params: {
						relatedId: $related_id,
					}
				}).then(function (data) {
					cm.entity_data = data;
				});

				function save() {
					if (!data.attendee.comment_id) {
						REST.create({
							data: {
								"comment": $scope.attendee.comment,
								"entity_type": 'people_group',
								"entity_id": $scope.attendee.people_group_id,
								"source_type": vm.bladeParams.modelTable,
								"source_type_id": vm.bladeParams.groupId,
							}
						}).then(function (data) {
							$uibModalInstance.dismiss('cancel');
						});
					} else {
						REST.update({
							id: data.attendee.comment_id,
							data: {
								"comment": $scope.attendee.comment,
								"entity_type": $scope.attendee.people_type,
								"entity_id": $scope.attendee.people_id,
								"source_type": vm.bladeParams.modelTable,
								"source_type_id": $entity_id,
							}
						}).then(function (data) {

							$uibModalInstance.dismiss('cancel');
						});
					}
				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				};
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				bladeParams: function () {
					return vm.bladeParams;
				},
				data: function () {
					return {
						attendee: attendee,
					};
				}
			}
		});
	};
	return vm;
});

