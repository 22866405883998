kv.controller('popupChartCtrl', function ($uibModalInstance, params, $scope, $injector, $timeout) {
    let vm = this;

    // Ensure the params data is correctly passed
    vm.title = params.title;
    vm.chartConfig = params.chartConfig;
    // vm.chartConfig.series = [{
    //     data: Object.values(vm.data.chart)
    // }];

    // Modal is rendered
    vm.modal = $uibModalInstance;


    vm.cancel = function() {
        vm.modal.dismiss('cancel');
    };

    $(document).ready(function() {
        // Simulate opening the modal
        $('#myModal').show();

        // Render the chart after the modal is shown
        $('#chart').highcharts(vm.chartConfig);
    });

    return vm;
});
