kv.controller('legalCaseCnscViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'legal_case_cnsc';
    vm.entityType = 'legal_case_cnsc';
    vm.groups = {};

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    vm.entityId = page_entity_id;
    vm.commentsConfig = {
        entity_type: vm.entity
    };

    vm.goBack = function () {
        $state.go("app.legal_case_cnsc");
    };


    vm.edit = function () {
        $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
    };

    vm.editResponsible = function (responsibles, id) {
        vm.openModal({
            templateUrl: "cnsc_change_responsible-edit",
            controller: 'legalCaseCnscEditCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params: {
                    cnsc_responsibles: responsibles,
                    case_id: id,
                    from_view: true
                }
            }
        }).then(function (response) {
            if (!response) return;
        });
    };

    return vm;
});
